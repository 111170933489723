const {
  GENDER_MALE,
} = require('./../../../genderList')
const {
  PLACE_NIMBRAL,
} = require('./../../../placeIdList')

module.exports = {
  id: PLACE_NIMBRAL,
  name: {
    nominative: 'Нимбрал',
    genitive: 'Нимбрала',
    dative: 'Нимбралу',
    accusative: 'Нимбрал',
    instrumental: 'Нимбралом',
    prepositional: 'Нимбрале',
  },
  nameEn: 'Nimbral',
  genderId: GENDER_MALE,
}
