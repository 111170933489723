const {
  GENDER_MIDDLE,
} = require('./../../../genderList')
const {
  PLACE_WATERDEEP,
} = require('./../../../placeIdList')

module.exports = {
  id: PLACE_WATERDEEP,
  name: {
    nominative: 'Глубоководье',
    genitive: 'Глубоководья',
    dative: 'Глубоководью',
    accusative: 'Глубоководье',
    instrumental: 'Глубоководьем',
    prepositional: 'Глубоководье',
  },
  nameAlt: 'Вотердип',
  nameEn: 'Waterdeep',
  genderId: GENDER_MIDDLE,
}
