module.exports = [
  require('./samarach'),
  require('./savage_north'),
  require('./sembia'),
  require('./semphar'),
  require('./sespech'),
  require('./shaar'),
  require('./silver_marches'),
  require('./sword_coast'),
]
