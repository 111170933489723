const {
  GENDER_MULTIPLE,
} = require('./../../../genderList')
const {
  PLACE_MOONSHAES,
} = require('./../../../placeIdList')

module.exports = {
  id: PLACE_MOONSHAES,
  name: {
    nominative: 'Муншае',
    genitive: 'Муншае',
    dative: 'Муншае',
    accusative: 'Муншае',
    instrumental: 'Муншае',
    prepositional: 'Муншае',
  },
  nameEn: 'the Moonshaes',
  genderId: GENDER_MULTIPLE,
}
