import arrify from 'arrify'

import SEARCH_PROP_NAME from '@/constants/SEARCH_PROP_NAME'
import DEFAULT_FILTER_VALUE from '@/constants/DEFAULT_FILTER_VALUE'
import languageTypeList from '@/constants/languageTypeList'
import { readyLanguagesAlphabetIdList } from '@/constants/languageList'
import { alphabetCollection } from '@/constants/alphabetList'
import {
  FILTER_TYPE_INPUT,
  FILTER_TYPE_SELECT,
} from '@/constants/filterTypes'

import upLetter from '@/utils/upLetter'
import sortByText from '@/utils/sortByText'
import generateNameStr from '@/utils/generateNameStr'

export default [
  {
    label: 'Тип языка',
    type: FILTER_TYPE_SELECT,
    title: 'Какого язык типа?',
    propName: 'type',
    value: DEFAULT_FILTER_VALUE,
    list: [
      {
        value: DEFAULT_FILTER_VALUE,
        text: 'Любой'
      },
      ...languageTypeList
        .map(
          ({ name, title, id: value }) => {
            const {header} = generateNameStr({ name })

            return ({ value, title, text: upLetter(header) })
          }
        )
        .sort(sortByText)
    ]
  },
  {
    label: 'Алфавит',
    type: FILTER_TYPE_SELECT,
    title: 'Какой используется алфавит?',
    propName: 'alphabetId',
    value: DEFAULT_FILTER_VALUE,
    list: [
      {
        value: DEFAULT_FILTER_VALUE,
        text: 'Любой',
      },
      ...readyLanguagesAlphabetIdList
        .map(
          alphabetId => {
            const {description, name} = alphabetCollection[alphabetId]
            const descriptionFirst = arrify(description)[0]
            const title = descriptionFirst
              ? descriptionFirst.text
              : ''

            return {
              value: alphabetId,
              title: title,
              text: upLetter(name),
            }
          }
        )
        .sort(sortByText)
    ]
  },
  {
    label: 'Введите название языка на русском или английском',
    type: FILTER_TYPE_INPUT,
    propName: SEARCH_PROP_NAME,
    value: DEFAULT_FILTER_VALUE,
  },
]
