const {
  GENDER_MALE,
} = require('./../../../genderList')
const {
  PLACE_DAMBRATH,
} = require('./../../../placeIdList')

module.exports = {
  id: PLACE_DAMBRATH,
  name: {
    nominative: 'Дамбрат',
    genitive: 'Дамбрата',
    dative: 'Дамбрату',
    accusative: 'Дамбрат',
    instrumental: 'Дамбратом',
    prepositional: 'Дамбрате',
  },
  nameEn: 'Dambrath',
  genderId: GENDER_MALE,
}
