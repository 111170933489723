const {
  GENDER_MALE,
} = require('./../../../genderList')
const {
  PLACE_CHONDATH,
} = require('./../../../placeIdList')

module.exports = {
  id: PLACE_CHONDATH,
  name: {
    nominative: 'Чондат',
    genitive: 'Чондата',
    dative: 'Чондату',
    accusative: 'Чондата',
    instrumental: 'Чондатом',
    prepositional: 'Чондате',
  },
  nameEn: 'Chondath',
  genderId: GENDER_MALE,
}
