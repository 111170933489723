import React from 'react'

import commonName from './constants/commonName'
import formatDict from './utils/formatDict'

import DictionaryComponent from './DictionaryComponent'

const DictionaryContainer = ({ dictionary, name }) => {
  if (dictionary) {
    const {list, source = null} = dictionary
    const listToCommon = formatDict(list)
    const listFromCommon = formatDict(
        list.map(
          ([commonWord, langWord]) => [langWord, commonWord]
        )
    )

    return (
      <>
        <DictionaryComponent
          header={`${commonName}-${name}`}
          list={listToCommon}
          source={source}
        />
        <DictionaryComponent
          header={`${name}-${commonName}`}
          list={listFromCommon}
          source={source}
        />
      </>
    )
  }

  return null
}

export default DictionaryContainer
