const {
  GENDER_MALE,
} = require('./../../../genderList')
const {
  PLACE_LUSKAN,
} = require('./../../../placeIdList')

module.exports = {
  id: PLACE_LUSKAN,
  name: {
    nominative: 'Лускан',
    genitive: 'Лускана',
    dative: 'Лускану',
    accusative: 'Лускан',
    instrumental: 'Лусканом',
    prepositional: 'Лускане',
  },
  nameEn: 'Luskan',
  genderId: GENDER_MALE,
}
