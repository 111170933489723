const {
  GENDER_MALE,
} = require('./../../../genderList')
const {
  PLACE_VAR,
} = require('./../../../placeIdList')

module.exports = {
  id: PLACE_VAR,
  name: {
    nominative: 'Вар',
    genitive: 'Вара',
    dative: 'Вару',
    accusative: 'Вар',
    instrumental: 'Варом',
    prepositional: 'Варе',
  },
  nameEn: 'Var',
  genderId: GENDER_MALE,
}
