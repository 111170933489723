const {
  GENDER_MIDDLE,
} = require('./../../../genderList')
const {
  PLACE_MOONSEA,
} = require('./../../../placeIdList')

module.exports = {
  id: PLACE_MOONSEA,
  name: {
    nominative: 'Лунное Море',
    genitive: 'Лунного Моря',
    dative: 'Лунному Морю',
    accusative: 'Лунное Море',
    instrumental: 'Лунным Морем',
    prepositional: 'Лунном Море',
  },
  nameEn: 'the Moonsea',
  genderId: GENDER_MIDDLE,
}
