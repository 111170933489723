const {
  GENDER_MALE,
} = require('./../../../genderList')
const {
  PLACE_TETHYR,
} = require('./../../../placeIdList')

module.exports = {
  id: PLACE_TETHYR,
  name: {
    nominative: 'Тетир',
    genitive: 'Тетира',
    dative: 'Тетиру',
    accusative: 'Тетир',
    instrumental: 'Тетиром',
    prepositional: 'Тетире',
  },
  nameEn: 'Tethyr',
  genderId: GENDER_MALE,
}
