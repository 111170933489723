import React from 'react'
import { Link } from 'gatsby'

import languageList from '@/constants/languageList'
import Catalog from '@/components/Catalog'
import {
  LANG_COMMON,
  LANG_DRUIDIC,
  LANG_DWARVISH,
  LANG_GOBLIN,
  LANG_THORASS,
} from '@/constants/languageIdList'

import generateLanguagePageUrlById from '@/utils/generateLanguagePageUrlById'
import sortByName from '@/utils/sortByName'

import filterList from './constants/filterList'

const LanguageCatalogContainer = () => {
  const list = languageList
    .filter(({isReady}) => isReady)
    .sort(sortByName)

  return (
    <Catalog
      filterListOriginal={filterList}
      itemList={list}
      itemNameList={list}
      pageTitle='Каталог языков в Dungeons & Dragons'
      pageUrlGenerator={generateLanguagePageUrlById}
    >
      <p>Здесь собраны языки разных рас и народов D&D: их описания, алфавиты словари. Раздел будет пополняться и уточняться.</p>
      <p>Если у языка есть алфавит или алфавиты, то их описания и примеры тоже будут приведены. Про некоторые языки нет никакой информации, кроме названия, алфавита и области применения. Такие языки не будут появляться в этом разделе.</p>
      <p>Некоторые языки называются в разных материалах по-разному, например «<Link to={generateLanguagePageUrlById(LANG_DRUIDIC)}>друидический</Link>» и «<Link to={generateLanguagePageUrlById(LANG_DRUIDIC)}>Друэдан</Link>». В этом случае достаточно очевидно, что одно из этих названий — обывательское название (<Link to={generateLanguagePageUrlById(LANG_DRUIDIC)}>друидический</Link>), а второе — что-то вроде самоназвания (<Link to={generateLanguagePageUrlById(LANG_DRUIDIC)}>Друэдан</Link>). В этом случае в каталоге используется самоназвание, как более правильное. Иногда только алфавит имеет особое название («Торасс» у <Link to={generateLanguagePageUrlById(LANG_COMMON)}>всеобщего языка</Link>), иногда алфавит совпадает с названием языка.</p>
      <p>В некоторых случаях не очень понятно про какой из языков идёт речь (например, Торасс иногда описывается как мёртвый язык и называется «<Link to={generateLanguagePageUrlById(LANG_THORASS)}>старовсеобщим</Link>», а иногда описывается как языковая ветвь, в которую входят множество языков территорий, на которых, по другим материалам, говорят на <Link to={generateLanguagePageUrlById(LANG_COMMON)}>всеобщем языке</Link>).</p>
      <p>Информация о языках встречается нечасто и она довольно скудная, поэтому её приходится брать в основном из старых изданий D&D, которые иногда противоречат друг другу. У некоторых языков указаны взаимоисключающие алфавиты (например, Детек и Давек у <Link to={generateLanguagePageUrlById(LANG_DWARVISH)}>дварфийского языка</Link>), потому что употребление конкретного алфавита зависит от сеттинга.</p>
      <p>При этом некоторых из часто используемых языков нет почти никаких описаний (например, у <Link to={generateLanguagePageUrlById(LANG_GOBLIN)}>Гукляка — гоблинского языка</Link>), поэтому как источники информации здесь используются не только игровые руководства, а даже выпущенные WotC художественные книги и журнал Dragon Magazine. Эти моменты будут уточняться.</p>
    </Catalog>
  )
}

export default LanguageCatalogContainer
