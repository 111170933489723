const {
  GENDER_MIDDLE,
} = require('./../../../genderList')
const {
  PLACE_LAKE_OF_STEAM,
} = require('./../../../placeIdList')

module.exports = {
  id: PLACE_LAKE_OF_STEAM,
  name: {
    nominative: 'Озеро Пара',
    genitive: 'Озера Пара',
    dative: 'Озеру Пара',
    accusative: 'Озеро Пара',
    instrumental: 'Озером Пара',
    prepositional: 'Озере Пара',
  },
  nameEn: 'Lake of Steam',
  genderId: GENDER_MIDDLE,
}
