const {
  GENDER_MALE,
} = require('./../../../genderList')
const {
  PLACE_SAVAGE_NORTH,
} = require('./../../../placeIdList')

module.exports = {
  id: PLACE_SAVAGE_NORTH,
  name: {
    nominative: 'Дикий Север',
    genitive: 'Дикого Севера',
    dative: 'Дикому Северу',
    accusative: 'Дикий Север',
    instrumental: 'Диким Севером',
    prepositional: 'Диком Севере',
  },
  nameEn: 'the Savage North',
  genderId: GENDER_MALE,
}
