const {
  GENDER_FEMALE,
} = require('./../../../genderList')
const {
  PLACE_MAZTICA,
} = require('./../../../placeIdList')

module.exports = {
  id: PLACE_MAZTICA,
  name: {
    nominative: 'Мацтика',
    genitive: 'Мацтикы',
    dative: 'Мацтике',
    accusative: 'Мацтику',
    instrumental: 'Мацтикой',
    prepositional: 'Мацтике',
  },
  nameEn: 'Maztica',
  genderId: GENDER_FEMALE,
}
