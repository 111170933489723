const {
  SOURCE_BGDIA,
  SOURCE_DND1_CotR,
  SOURCE_DND4_DCD,
  SOURCE_SKT,
  SOURCE_PHB,
  SOURCE_SCAG,
} = require('./../sourceList')
const {
  ALPHABET_DETHEK,
  ALPHABET_GIANT_RUNES,
  ALPHABET_INFERNAL,
  ALPHABET_IOKHARIC,
  ALPHABET_ESPRUAR,
  ALPHABET_THORASS,
} = require('./../alphabetList')
const {
  LANG_GIANT,
  LANG_DWARVISH,
  LANG_INFERNAL,
} = require('./../languageIdList')

const Dethek_Alphabet_SCAG_img = require('./../../images/alphabets/dethek-alphabet-SCAG.jpg')
const Dethek_Dwarvish_Table_1_SCAG_img = require('./../../images/alphabets/dethek_dwarvish_table_1.png')
const Dwarvish_Sample_PHB_img = require('./../../images/alphabets/dwarvish-sample-PHB.jpg')
const Espruar_Alphabet_SCAG_img = require('./../../images/alphabets/espruar-alphabet-SCAG.jpg')
const Espruar_Message_SCAG_img = require('./../../images/alphabets/espruar_message_SCAG.png')
const Giant_Runes_SKT_img = require('./../../images/alphabets/giant_runes.png')
const Infernal_Rapture_Menu_BGDIA_img = require('./../../images/alphabets/infernal-rapture-menu.png')
const Infernal_Script_BGDIA_img = require('./../../images/alphabets/infernal_script.png')
const Iokharic_Script_Alphabet_DND4_DCD_img = require('./../../images/alphabets/iokharic_script_alphabet_DND4_DCD.png')
const Thorass_Common_SCAG_img = require('./../../images/alphabets/thorass-common.png')
const Thorass_Dethek_Espruar_Alphabet_CotR_1ed_img = require('./../../images/alphabets/thorass-dethek-espruar-alphabet-cotr_1ed.jpg')

module.exports = [
  {
    src: Infernal_Script_BGDIA_img,
    alphabetId: ALPHABET_INFERNAL,
    languageId: LANG_INFERNAL,
    text: `Инфернальное письмо, буквы и цифры Инфернального языка`,
    source: {
      id: SOURCE_BGDIA,
    },
  },
  {
    src: Infernal_Rapture_Menu_BGDIA_img,
    alphabetId: ALPHABET_INFERNAL,
    text: `Меню ресторана «Инфернальный восторг», написано инфернальным письмом`,
    source: {
      id: SOURCE_BGDIA,
    },
  },
  {
    src: Thorass_Common_SCAG_img,
    alphabetId: ALPHABET_THORASS,
    text: `Алфавит Торасс, буквы и цифры Всеобщего языка`,
    source: {
      id: SOURCE_SCAG,
    },
  },
  {
    src: Iokharic_Script_Alphabet_DND4_DCD_img,
    alphabetId: ALPHABET_IOKHARIC,
    text: `Алфавит Иохарик, буквы и цифры Драконика`,
    source: {
      id: SOURCE_DND4_DCD,
    },
  },
  {
    src: Espruar_Alphabet_SCAG_img,
    alphabetId: ALPHABET_ESPRUAR,
    text: `Алфавит Эспруар, буквы и цифры эльфийского языка`,
    source: {
      id: SOURCE_SCAG,
    },
  },
  {
    src: Espruar_Message_SCAG_img,
    alphabetId: ALPHABET_ESPRUAR,
    text: `Записка, надпись алфавитом Эспруар на Всеобщем языке: «A True Friend As The Trees And The Water Are True Friends.»`,
    source: {
      id: SOURCE_SCAG,
    },
  },
  {
    src: Dethek_Alphabet_SCAG_img,
    alphabetId: ALPHABET_DETHEK,
    text: `Алфавит Детек, буквы и цифры дварфийского языка`,
    source: {
      id: SOURCE_SCAG,
    },
  },
  {
    src: Dwarvish_Sample_PHB_img,
    alphabetId: ALPHABET_DETHEK,
    text: `Дварфийская письменность, пример алфавита`,
    source: {
      id: SOURCE_PHB,
    },
  },
  {
    src: Thorass_Dethek_Espruar_Alphabet_CotR_1ed_img,
    alphabetId: [
      ALPHABET_DETHEK,
      ALPHABET_ESPRUAR,
      ALPHABET_THORASS,
    ],
    text: `Образцы алфавитов Детек, Торасс и Эспруар`,
    source: {
      id: SOURCE_DND1_CotR,
    },
  },
  {
    src: Dethek_Dwarvish_Table_1_SCAG_img,
    alphabetId: ALPHABET_DETHEK,
    languageId: LANG_DWARVISH,
    text: `Табличка, надпись алфавитом Детек на Всеобщем языке: «That which crawls knows me not. Seek near the harpers silent strings. Heavier than it should be. Opened it could be. To see what harping brings.»`,
    source: {
      id: SOURCE_SCAG,
    },
  },
  {
    src: Giant_Runes_SKT_img,
    alphabetId: ALPHABET_GIANT_RUNES,
    languageId: LANG_GIANT,
    text: `Великаньи руны`,
    source: {
      id: SOURCE_SKT,
    },
  },
]
