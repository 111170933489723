const {
  GENDER_MULTIPLE,
} = require('./../../../genderList')
const {
  PLACE_BLACK_JUNGLE,
} = require('./../../../placeIdList')

module.exports = {
  id: PLACE_BLACK_JUNGLE,
  name: {
    nominative: 'Чёрные Джунгли',
    genitive: 'Чёрных Джунглей',
    dative: 'Чёрным Джунглям',
    accusative: 'Чёрные Джунгли',
    instrumental: 'Чёрными Джунглями',
    prepositional: 'Чёрных Джунглях',
  },
  nameEn: 'Black Jungle',
  genderId: GENDER_MULTIPLE,
}
