const {
  GENDER_MALE,
} = require('./../../../genderList')
const {
  PLACE_IMPILTUR,
} = require('./../../../placeIdList')

module.exports = {
  id: PLACE_IMPILTUR,
  name: {
    nominative: 'Импильтур',
    genitive: 'Импильтура',
    dative: 'Импильтуру',
    accusative: 'Импильтура',
    instrumental: 'Импильтуром',
    prepositional: 'Импильтуре',
  },
  nameEn: 'Impiltur',
  genderId: GENDER_MALE,
}
