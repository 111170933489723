const {
  GENDER_MIDDLE,
} = require('./../../../genderList')
const {
  PLACE_INNER_SEA,
} = require('./../../../placeIdList')

module.exports = {
  id: PLACE_INNER_SEA,
  name: {
    nominative: 'Внутреннее Море',
    genitive: 'Внутреннего Моря',
    dative: 'Внутреннему Морю',
    accusative: 'Внутреннее Море',
    instrumental: 'Внутреннем Морем',
    prepositional: 'Внутреннем Море',
  },
  nameEn: 'Inner Sea',
  genderId: GENDER_MIDDLE,
}
