const {
  GENDER_FEMALE,
} = require('./../../../genderList')
const {
  PLACE_GREAT_DALE,
} = require('./../../../placeIdList')

module.exports = {
  id: PLACE_GREAT_DALE,
  name: {
    nominative: 'Великая Долина',
    genitive: 'Великой Долины',
    dative: 'Великой Долине',
    accusative: 'Великую Долину',
    instrumental: 'Великой Долиной',
    prepositional: 'Великой Долине',
  },
  nameEn: 'the Great Dale',
  genderId: GENDER_FEMALE,
}
