const {
  GENDER_MIDDLE,
} = require('./../../../genderList')
const {
  PLACE_SWORD_COAST,
} = require('./../../../placeIdList')

module.exports = {
  id: PLACE_SWORD_COAST,
  name: {
    nominative: 'Побережье Меча',
    genitive: 'Побережья Меча',
    dative: 'Побережью Меча',
    accusative: 'Побережье Меча',
    instrumental: 'Побережьем Меча',
    prepositional: 'Побережье Меча',
  },
  nameEn: 'the Sword Coast',
  genderId: GENDER_MIDDLE,
}
