import React from 'react'
import { Link } from "gatsby"

import generateLanguagePageUrlById from '@/utils/generateLanguagePageUrlById'

import Span from '@/components/Span'

import { languageCollection } from '@/constants/languageList'

const DialectOf = ({ langId }) => {
  if (langId) {
    const {nameByCase: {genitive: name}, isReady} = languageCollection[langId]
    const Renderer = isReady ? Link : Span
    const url = isReady
      ? generateLanguagePageUrlById(langId)
      : null

    return (
      <p>
        <b>Диалект</b> <Renderer to={url}>{name}</Renderer>
      </p>
    )
  }
  return null
}

export default DialectOf
