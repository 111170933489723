const {
  GENDER_FEMALE,
} = require('./../../../genderList')
const {
  PLACE_ZAKHARA,
} = require('./../../../placeIdList')

module.exports = {
  id: PLACE_ZAKHARA,
  name: {
    nominative: 'Захара',
    genitive: 'Захары',
    dative: 'Захаре',
    accusative: 'Захару',
    instrumental: 'Захарой',
    prepositional: 'Захаре',
  },
  nameEn: 'Zakhara',
  genderId: GENDER_FEMALE,
}
