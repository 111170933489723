const {
  GENDER_MALE,
} = require('./../../../genderList')
const {
  PLACE_ALTUMBEL,
} = require('./../../../placeIdList')

module.exports = {
  id: PLACE_ALTUMBEL,
  name: {
    nominative: 'Алтумбель',
    genitive: 'Алтумбел',
    dative: 'Алтумбелю',
    accusative: 'Алтумбеля',
    instrumental: 'Алтумбелем',
    prepositional: 'Алтумбеле',
  },
  nameEn: 'Altumbel',
  genderId: GENDER_MALE,
}
