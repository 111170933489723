const {
  GENDER_MALE,
} = require('./../../../genderList')
const {
  PLACE_CALIMSHAN,
} = require('./../../../placeIdList')

module.exports = {
  id: PLACE_CALIMSHAN,
  name: {
    nominative: 'Калимшан',
    genitive: 'Калимшана',
    dative: 'Калимшану',
    accusative: 'Калимшан',
    instrumental: 'Калимшаном',
    prepositional: 'Калимшане',
  },
  nameEn: 'Calimshan',
  genderId: GENDER_MALE,
}
