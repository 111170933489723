const formatDict = list => Object.entries(
  list.reduce(
    (dict, [wordFrom, wordTo]) => ({
      ...dict,
      [wordFrom]: dict[wordFrom]
        ? [
          ...dict[wordFrom],
          wordTo,
        ]
        : [wordTo]
    }),
    {},
  )
)
  .map(
    ([wordFrom, wordToList]) => [
      wordFrom,
      wordToList.sort().join('; '),
    ]
  )
  .sort(
    ([A], [B]) => A.replace('-', '').toLowerCase() > B.replace('-', '').toLowerCase()
      ? 1
      : -1
  )

export default formatDict
