import PropTypes from 'prop-types'
import React from 'react';

import './PageHeaderStyles.less'

const PageHeaderComponent = ({main, mainSub, sub, subSub, className = ''}) => (
  <header className={`PageHeader ${className}`}>
    <h1 className='PageHeader_main'>{main}</h1>
    {mainSub && <div className='PageHeader_mainSub'>{mainSub}</div>}
    <div className='PageHeader_sub'>{sub}</div>
    {subSub && <div className='PageHeader_subSub'>{subSub}</div>}
  </header>
)

PageHeaderComponent.propTypes = {
  main: PropTypes.string.isRequired,
  mainSub: PropTypes.string.isRequired,
  sub: PropTypes.string.isRequired,
  subSub: PropTypes.string.isRequired,
}

export default PageHeaderComponent
