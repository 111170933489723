const {
  GENDER_MALE,
} = require('./../../../genderList')
const {
  PLACE_TASHALAR,
} = require('./../../../placeIdList')

module.exports = {
  id: PLACE_TASHALAR,
  name: {
    nominative: 'Ташалар',
    genitive: 'Ташалара',
    dative: 'Ташалару',
    accusative: 'Ташалар',
    instrumental: 'Ташаларом',
    prepositional: 'Ташаларе',
  },
  nameEn: 'Tashalar',
  genderId: GENDER_MALE,
}
