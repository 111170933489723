import React from 'react'

import { languageGroupCollection } from '@/constants/languageGroupList'

const LanguageGroup = ({langGroupId}) => langGroupId
  ? (
    <p>
      <b>Языковая группа: </b>
      <span>{languageGroupCollection[langGroupId].name}</span>
    </p>
  )
  : null

export default LanguageGroup
