const {
  GENDER_MULTIPLE,
} = require('./../../../genderList')
const {
  PLACE_HORDELANDS,
} = require('./../../../placeIdList')

module.exports = {
  id: PLACE_HORDELANDS,
  name: {
    nominative: 'Земли Орды',
    genitive: 'Земель Орды',
    dative: 'Землям Орды',
    accusative: 'Земли Орды',
    instrumental: 'Землями Орды',
    prepositional: 'Землях Орды',
  },
  nameEn: 'Hordelands',
  genderId: GENDER_MULTIPLE,
}
