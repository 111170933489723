const {
  GENDER_MULTIPLE,
} = require('./../../../genderList')
const {
  PLACE_SILVER_MARCHES,
} = require('./../../../placeIdList')

module.exports = {
  id: PLACE_SILVER_MARCHES,
  name: {
    nominative: 'Серебряные Пределы',
    genitive: 'Серебряных Пределов',
    dative: 'Серебряным Пределам',
    accusative: 'Серебряные Пределы',
    instrumental: 'Серебряными Пределами',
    prepositional: 'Серебряных Пределах',
  },
  nameEn: 'the Silver Marches',
  genderId: GENDER_MULTIPLE,
}
