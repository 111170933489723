const {
  GENDER_MALE,
} = require('./../../../genderList')
const {
  PLACE_THAY,
} = require('./../../../placeIdList')

module.exports = {
  id: PLACE_THAY,
  name: {
    nominative: 'Тэй',
    genitive: 'Тэя',
    dative: 'Тэю',
    accusative: 'Тэй',
    instrumental: 'Тэем',
    prepositional: 'Тэе',
  },
  nameEn: 'Thay',
  genderId: GENDER_MALE,
}
