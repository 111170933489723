const {
  GENDER_MULTIPLE,
} = require('./../../../genderList')
const {
  PLACE_DALELANDS,
} = require('./../../../placeIdList')

module.exports = {
  id: PLACE_DALELANDS,
  name: {
    nominative: 'Долины',
    genitive: 'Долин',
    dative: 'Долинам',
    accusative: 'Долины',
    instrumental: 'Долинами',
    prepositional: 'Долинах',
  },
  nameEn: 'the Dalelands',
  genderId: GENDER_MULTIPLE,
}
