const {
  GENDER_MALE,
} = require('./../../../genderList')
const {
  PLACE_SEMPHAR,
} = require('./../../../placeIdList')

module.exports = {
  id: PLACE_SEMPHAR,
  name: {
    nominative: 'Семфар',
    genitive: 'Семфара',
    dative: 'Семфару',
    accusative: 'Семфар',
    instrumental: 'Семфаром',
    prepositional: 'Семфаре',
  },
  nameEn: 'Semphar',
  genderId: GENDER_MALE,
}
