const {
  GENDER_MULTIPLE,
} = require('./../../../genderList')
const {
  PLACE_NELANTHER_ISLES,
} = require('./../../../placeIdList')

module.exports = {
  id: PLACE_NELANTHER_ISLES,
  name: {
    nominative: 'Острова Нелантер',
    genitive: 'Островов Нелантер',
    dative: 'Островам Нелантер',
    accusative: 'Острова Нелантер',
    instrumental: 'Островами Нелантер',
    prepositional: 'Островах Нелантер',
  },
  nameEn: 'Nelanther Isles',
  genderId: GENDER_MULTIPLE,
}
