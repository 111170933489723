const {
  SOURCE_MM,
} = require('./../../../sourceList')
const {
  GENDER_MIDDLE,
} = require('./../../../genderList')
const {
  PLACE_UNDERDARK,
} = require('./../../../placeIdList')

module.exports = {
  id: PLACE_UNDERDARK,
  name: {
    nominative: 'Подземье',
    genitive: 'Подземья',
    dative: 'Подземью',
    accusative: 'Подземье',
    instrumental: 'Подземьем',
    prepositional: 'Подземье',
  },
  nameEn: 'Underdark',
  genderId: GENDER_MIDDLE,
  description: {
    header: `Подземье`,
    text: `Нет подземелья больше, чем Подземье, мир под поверхностью земли. Это огромное подземное царство, где чудовища привыкли жить в темноте. Это место неосвещённых пещер, соединённых между собой, где нет ни малейшего дуновения ветра. Можно потратить всю жизнь на исследование Подземья, но даже тогда Вы изучите лишь малую часть.`,
    source: {
      id: SOURCE_MM,
      page: 6,
    },
  },
}
