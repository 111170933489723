const {
  GENDER_MALE,
} = require('./../../../genderList')
const {
  PLACE_RASHEMEN,
} = require('./../../../placeIdList')

module.exports = {
  id: PLACE_RASHEMEN,
  name: {
    nominative: 'Рашемен',
    genitive: 'Рашемена',
    dative: 'Рашемену',
    accusative: 'Рашемен',
    instrumental: 'Рашеменом',
    prepositional: 'Рашемене',
  },
  nameEn: 'Rashemen',
  genderId: GENDER_MALE,
}
