const {
  GENDER_MALE,
} = require('./../../../genderList')
const {
  PLACE_CHULT,
} = require('./../../../placeIdList')

module.exports = {
  id: PLACE_CHULT,
  name: {
    nominative: 'Чульт',
    genitive: 'Чульта',
    dative: 'Чульту',
    accusative: 'Чульт',
    instrumental: 'Чультом',
    prepositional: 'Чульте',
  },
  nameEn: 'Chult',
  genderId: GENDER_MALE,
}
