const {
  GENDER_MALE,
} = require('./../../../genderList')
const {
  PLACE_FAERUN,
} = require('./../../../placeIdList')

module.exports = {
  id: PLACE_FAERUN,
  name: {
    nominative: 'Фаэрун',
    genitive: 'Фаэруна',
    dative: 'Фаэруну',
    accusative: 'Фаэрун',
    instrumental: 'Фаэруном',
    prepositional: 'Фаэруне',
  },
  nameEn: 'Faerun',
  genderId: GENDER_MALE,
}
