const {
  GENDER_MALE,
} = require('./../../../genderList')
const {
  PLACE_ESTAGUND,
} = require('./../../../placeIdList')

module.exports = {
  id: PLACE_ESTAGUND,
  name: {
    nominative: 'Эстагунд',
    genitive: 'Эстагунда',
    dative: 'Эстагунду',
    accusative: 'Эстагунд',
    instrumental: 'Эстагундом',
    prepositional: 'Эстагунде',
  },
  nameEn: 'Estagund',
  genderId: GENDER_MALE,
}
