const {
  GENDER_FEMALE,
} = require('./../../../genderList')
const {
  PLACE_SEMBIA,
} = require('./../../../placeIdList')

module.exports = {
  id: PLACE_SEMBIA,
  name: {
    nominative: 'Сембия',
    genitive: 'Сембии',
    dative: 'Сембии',
    accusative: 'Сембию',
    instrumental: 'Сембией',
    prepositional: 'Сембии',
  },
  nameEn: 'Sembia',
  genderId: GENDER_FEMALE,
}
