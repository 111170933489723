const {
  GENDER_MULTIPLE,
} = require('./../../../genderList')
const {
  PLACE_MHAIR_JUNGLE,
} = require('./../../../placeIdList')

module.exports = {
  id: PLACE_MHAIR_JUNGLE,
  name: {
    nominative: 'Джунгли Маир',
    genitive: 'Джунглей Маир',
    dative: 'Джунглям Маир',
    accusative: 'Джунгли Маир',
    instrumental: 'Джунглями Маир',
    prepositional: 'Джунглях Маир',
  },
  nameEn: 'Mhair Jungle',
  genderId: GENDER_MULTIPLE,
}
