import arrify from 'arrify'
import PropTypes from 'prop-types'
import React from 'react';

import upLetter from '@/utils/upLetter'

import PageHeaderComponent from './PageHeaderComponent';

const PageHeaderContainer = ({name, nameAlt, nameEn, nameEnAlt, ...rest}) =>  {
  const nameAltText = nameAlt
    ? `(${arrify(nameAlt).map(e => upLetter(e)).join(', ')})`
    : ''
  const nameEnAltText = nameEnAlt
    ? `(${arrify(nameEnAlt).map(e => upLetter(e)).join(', ')})`
    : ''

  return (
    <PageHeaderComponent
      main={upLetter(name)}
      mainSub={nameAltText}
      sub={upLetter(nameEn)}
      subSub={nameEnAltText}
      {...rest}
    />
  )
}

PageHeaderContainer.propTypes = {
  name: PropTypes.string.isRequired,
  nameAlt: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.string),
  ]),
  nameEn: PropTypes.string.isRequired,
  nameEnAlt: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.string),
  ]),
}

PageHeaderContainer.defaultProps = {
  nameAlt: '',
  nameEnAlt: '',
}

export default PageHeaderContainer
