const {
  GENDER_FEMALE,
} = require('./../../../genderList')
const {
  PLACE_VAASA,
} = require('./../../../placeIdList')

module.exports = {
  id: PLACE_VAASA,
  name: {
    nominative: 'Вааса',
    genitive: 'Ваасы',
    dative: 'Ваасе',
    accusative: 'Ваасу',
    instrumental: 'Ваасой',
    prepositional: 'Ваасе',
  },
  nameEn: 'Vaasa',
  genderId: GENDER_FEMALE,
}
