import React from 'react'

import SourceInfo from '@/components/SourceInfo'

import './DictionaryStyles.less'

const DictionaryComponent = ({ header, list = [], source }) => (
  <section className='Dictionary'>
    <header className='Dictionary_header'>Словарь: {header}</header>
    <dl className='Dictionary_list'>
      {
        list.map(
          ([original, translation], i) => (
            <React.Fragment key={i}>
              <dt className='Dictionary_word Dictionary_word-original'>{original}</dt>
              <dd className='Dictionary_word Dictionary_word-translation'>{translation}</dd>
            </React.Fragment>
          )
        )
      }
    </dl>
    <footer className='Dictionary_footer'>
      {
        source
          ? (
            <SourceInfo
              className='Dictionary_source'
              source={source}
              useFullName
            />
          )
          : null
      }
    </footer>
  </section>
)

export default DictionaryComponent
