const {
  GENDER_MALE,
} = require('./../../../genderList')
const {
  PLACE_SAMARACH,
} = require('./../../../placeIdList')

module.exports = {
  id: PLACE_SAMARACH,
  name: {
    nominative: 'Самарач',
    genitive: 'Самарача',
    dative: 'Самарачу',
    accusative: 'Самарач',
    instrumental: 'Самарачем',
    prepositional: 'Самараче',
  },
  nameEn: 'Samarach',
  genderId: GENDER_MALE,
}
