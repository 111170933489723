const {
  GENDER_FEMALE,
} = require('./../../../genderList')
const {
  PLACE_HALRUAA,
} = require('./../../../placeIdList')

module.exports = {
  id: PLACE_HALRUAA,
  name: {
    nominative: 'Халруаа',
    genitive: 'Халруаа',
    dative: 'Халруаа',
    accusative: 'Халруаа',
    instrumental: 'Халруаа',
    prepositional: 'Халруаа',
  },
  nameEn: 'Halruaa',
  genderId: GENDER_FEMALE,
}
