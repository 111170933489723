const {
  GENDER_FEMALE,
} = require('./../../../genderList')
const {
  PLACE_CHESSENTA,
} = require('./../../../placeIdList')

module.exports = {
  id: PLACE_CHESSENTA,
  name: {
    nominative: 'Чессента',
    genitive: 'Чессенты',
    dative: 'Чессенте',
    accusative: 'Чессенту',
    instrumental: 'Чессенте',
    prepositional: 'Чессенте',
  },
  nameEn: 'Chessenta',
  genderId: GENDER_FEMALE,
}
