const arrify = require('arrify')

const scriptImageList = require('./scriptImageList')

module.exports = scriptImageList.reduce(
  (collection, item) => item.alphabetId
    ? ({
      ...collection,
      ...arrify(item.alphabetId).reduce(
        (obj, alphabetId) => ({
          ...obj,
          [alphabetId]: obj[alphabetId]
            ? [
              ...obj[alphabetId],
              item,
            ]
            : [item]
        }),
        collection
      ),
    })
    : collection,
  {}
)
