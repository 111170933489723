import React from 'react'

import { languageSubGroupCollection } from '@/constants/languageSubGroupList'

const LanguageSubGroup = ({langSubGroupId}) => langSubGroupId
  ? (
    <p>
      <b>Языковая подгруппа: </b>
      <span>{languageSubGroupCollection[langSubGroupId].name}</span>
    </p>
  )
  : null

export default LanguageSubGroup
