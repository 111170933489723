import React from 'react'
import PropTypes from 'prop-types'

import ImageList from '@/components/ImageList'

import alphabetImageCollection from '@/constants/images/alphabetImageCollection'
import {alphabetCollection} from '@/constants/alphabetList'

const AlphabetImageListContainer = ({ id }) => {
  if (id) {
    const alphabet = alphabetCollection[id]
    const alphabetList = alphabetImageCollection[id]

    if (alphabetList) {
      return (
        <ImageList
          imageList={alphabetList}
          {...alphabet}
        />
      )
    }

    return null
  }

  return null
}

AlphabetImageListContainer.propTypes = {
  id: PropTypes.string.isRequired,
}

export default AlphabetImageListContainer
