const {
  GENDER_MIDDLE,
} = require('./../../../genderList')
const {
  PLACE_WESTERN_HEARTHLANDS,
} = require('./../../../placeIdList')

module.exports = {
  id: PLACE_WESTERN_HEARTHLANDS,
  name: {
    nominative: 'Западное Сердцеземье',
    genitive: 'Западного Сердцеземья',
    dative: 'Западному Сердцеземью',
    accusative: 'Западное Сердцеземье',
    instrumental: 'Западным Сердцеземьем',
    prepositional: 'Западном Сердцеземье',
  },
  nameEn: 'the Western Hearthlands',
  genderId: GENDER_MIDDLE,
}
