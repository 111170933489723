const {
  GENDER_MALE,
} = require('./../../../genderList')
const {
  PLACE_MINTARN,
} = require('./../../../placeIdList')

module.exports = {
  id: PLACE_MINTARN,
  name: {
    nominative: 'Минтарн',
    genitive: 'Минтарна',
    dative: 'Минтарну',
    accusative: 'Минтарн',
    instrumental: 'Минтарном',
    prepositional: 'Минтарне',
  },
  nameEn: 'Mintarn',
  genderId: GENDER_MALE,
}
