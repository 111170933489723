const {
  GENDER_MALE,
} = require('./../../../genderList')
const {
  PLACE_TURMISH,
} = require('./../../../placeIdList')

module.exports = {
  id: PLACE_TURMISH,
  name: {
    nominative: 'Тёрмиш',
    genitive: 'Тёрмиша',
    dative: 'Тёрмишу',
    accusative: 'Тёрмиш',
    instrumental: 'Тёрмишем',
    prepositional: 'Тёрмише',
  },
  nameAlt: 'Турмиш',
  nameEn: 'Turmish',
  genderId: GENDER_MALE,
}
