const {
  GENDER_MALE,
} = require('./../../../genderList')
const {
  PLACE_OMU,
} = require('./../../../placeIdList')

module.exports = {
  id: PLACE_OMU,
  name: {
    nominative: 'Ому',
    genitive: 'Ому',
    dative: 'Ому',
    accusative: 'Ому',
    instrumental: 'Ому',
    prepositional: 'Ому',
  },
  nameEn: 'Omu',
  genderId: GENDER_MALE,
}
