const {
  GENDER_MALE,
} = require('./../../../genderList')
const {
  PLACE_LUIREN,
} = require('./../../../placeIdList')

module.exports = {
  id: PLACE_LUIREN,
  name: {
    nominative: 'Луриен',
    genitive: 'Луриена',
    dative: 'Луриену',
    accusative: 'Луриен',
    instrumental: 'Луриеном',
    prepositional: 'Луриене',
  },
  nameEn: 'Luiren',
  genderId: GENDER_MALE,
}
