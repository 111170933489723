const {
  GENDER_MALE,
} = require('./../../../genderList')
const {
  PLACE_AGLAROND,
} = require('./../../../placeIdList')

module.exports = {
  id: PLACE_AGLAROND,
  name: {
    nominative: 'Агларонд',
    genitive: 'Агларонда',
    dative: 'Агларонду',
    accusative: 'Агларонд',
    instrumental: 'Агларондом',
    prepositional: 'Агларонде',
  },
  nameEn: 'Aglarond',
  genderId: GENDER_MALE,
}
