import React from 'react'

import './SpokenPlacesStyles.less'

const SpokenPlacesComponent = ({list = []}) => (
  <span className='SpokenPlaces'>
    <b>Регионы: </b>
    {
      list.map(
        (text, i) => (
          <span
            className='SpokenPlaces_place'
            key={i}
          >
            {text}
          </span>
        )
      )
    }
    </span>
)

export default SpokenPlacesComponent
