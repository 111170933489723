const {
  GENDER_FEMALE,
} = require('./../../../genderList')
const {
  PLACE_SHAAR,
} = require('./../../../placeIdList')

module.exports = {
  id: PLACE_SHAAR,
  name: {
    nominative: 'Шаар',
    genitive: 'Шаар',
    dative: 'Шаар',
    accusative: 'Шаар',
    instrumental: 'Шаар',
    prepositional: 'Шаар',
  },
  nameEn: 'the Shaar',
  genderId: GENDER_FEMALE,
}
