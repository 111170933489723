const {
  GENDER_MIDDLE,
} = require('./../../../genderList')
const {
  PLACE_DRAGON_COAST,
} = require('./../../../placeIdList')

module.exports = {
  id: PLACE_DRAGON_COAST,
  name: {
    nominative: 'Драконье побережье',
    genitive: 'Драконьего побережья',
    dative: 'Драконьему побережью',
    accusative: 'Драконье побережье',
    instrumental: 'Драконьим побережьем',
    prepositional: 'Драконьем побережье',
  },
  nameEn: 'the Dragon Coast',
  genderId: GENDER_MIDDLE,
}
