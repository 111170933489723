import arrify from 'arrify'
import React from 'react'

import { languageTypeCollection } from '@/constants/languageTypeList'

import './LanguageTypeStyles.less'

const LanguageType = ({type}) => type
  ? (
    <p className='LanguageType'>
      <b>Тип языка: </b>
      <ul className='LanguageType_list'>
        {
          arrify(type).map(
            (typeId) =>(
              <span
                className='LanguageType_item'
                key={typeId}
                title={languageTypeCollection[typeId].title}
              >
                {languageTypeCollection[typeId].name}
              </span>
            )
          )
        }
      </ul>
    </p>
  )
  : null

export default LanguageType
