const {
  GENDER_MALE,
} = require('./../../../genderList')
const {
  PLACE_DURPAR,
} = require('./../../../placeIdList')

module.exports = {
  id: PLACE_DURPAR,
  name: {
    nominative: 'Дурпар',
    genitive: 'Дурпара',
    dative: 'Дурпару',
    accusative: 'Дурпар',
    instrumental: 'Дурпаром',
    prepositional: 'Дурпаре',
  },
  nameEn: 'Durpar',
  genderId: GENDER_MALE,
}
