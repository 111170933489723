import React from 'react'

import './DialectListStyles.less'

const DialectListComponent = ({ dialectList }) => (
  <p className='DialectList'>
    <b>Диалекты:</b>

    <ul className='DialectList_list'>
      {
        dialectList.map(
          ({langId, name, Renderer, url}) => (
            <li
              className='DialectList_item'
              key={langId}
            >
              <Renderer to={url}>
                {name}
              </Renderer>
            </li>
          )
        )
      }
    </ul>
  </p>
)

export default DialectListComponent
