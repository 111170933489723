const {
  GENDER_FEMALE,
} = require('./../../../genderList')
const {
  PLACE_DAMARA,
} = require('./../../../placeIdList')

module.exports = {
  id: PLACE_DAMARA,
  name: {
    nominative: 'Дамара',
    genitive: 'Дамары',
    dative: 'Дамаре',
    accusative: 'Дамару',
    instrumental: 'Дамарой',
    prepositional: 'Дамаре',
  },
  nameEn: 'Damara',
  genderId: GENDER_FEMALE,
}
