const {
  GENDER_MALE,
} = require('./../../../genderList')
const {
  PLACE_LANTAN,
} = require('./../../../placeIdList')

module.exports = {
  id: PLACE_LANTAN,
  name: {
    nominative: 'Лантан',
    genitive: 'Лантана',
    dative: 'Лантану',
    accusative: 'Лантан',
    instrumental: 'Лантаном',
    prepositional: 'Лантане',
  },
  nameEn: 'Lantan',
  genderId: GENDER_MALE,
}
