const {
  GENDER_MALE,
} = require('./../../../genderList')
const {
  PLACE_NARFELL,
} = require('./../../../placeIdList')

module.exports = {
  id: PLACE_NARFELL,
  name: {
    nominative: 'Нарфелл',
    genitive: 'Нарфелла',
    dative: 'Нарфеллу',
    accusative: 'Нарфелл',
    instrumental: 'Нарфеллом',
    prepositional: 'Нарфелле',
  },
  nameEn: 'Narfell',
  genderId: GENDER_MALE,
}
