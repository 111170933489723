import arrify from 'arrify'
import React from 'react'
import { Link } from 'gatsby'

import generateLanguagePageUrlById from '@/utils/generateLanguagePageUrlById'
import Span from '@/components/Span'
import { languageCollection } from '@/constants/languageList'

import DialectListComponent from './DialectListComponent'

const DialectListContainer = ({ dialectId }) => {
  if (dialectId) {
    const dialectList = arrify(dialectId).map(
      langId => {
        const {name, isReady} = languageCollection[langId]
        const Renderer = isReady
          ? Link
          : Span
        const url = isReady
          ? generateLanguagePageUrlById(langId)
          : null

        return {
          langId,
          name,
          Renderer,
          url,
        }
      }
    )

    return (
      <DialectListComponent dialectList={dialectList}/>
    )
  }

  return null
}

export default DialectListContainer
