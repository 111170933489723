import React from 'react'

import { languageFamilyCollection } from '@/constants/languageFamilyList'

const LanguageFamily = ({langFamilyId}) => langFamilyId
  ? (
    <p>
      <b>Языковая семья: </b>
      <span>{languageFamilyCollection[langFamilyId].name}</span>
    </p>
  )
  : null

export default LanguageFamily
