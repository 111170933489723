const {
  GENDER_MALE,
} = require('./../../../genderList')
const {
  PLACE_VAST,
} = require('./../../../placeIdList')

module.exports = {
  id: PLACE_VAST,
  name: {
    nominative: 'Васт',
    genitive: 'Васта',
    dative: 'Васту',
    accusative: 'Васт',
    instrumental: 'Вастом',
    prepositional: 'Васте',
  },
  nameEn: 'the Vast',
  genderId: GENDER_MALE,
}
