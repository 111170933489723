const {
  GENDER_MALE,
} = require('./../../../genderList')
const {
  PLACE_VELDORN,
} = require('./../../../placeIdList')

module.exports = {
  id: PLACE_VELDORN,
  name: {
    nominative: 'Велдорн',
    genitive: 'Велдорна',
    dative: 'Велдорну',
    accusative: 'Велдорн',
    instrumental: 'Велдорном',
    prepositional: 'Велдорне',
  },
  nameEn: 'Veldorn',
  genderId: GENDER_MALE,
}
