import React from 'react'

import { placeCollection } from '@/constants/placeList'

import SpokenPlacesComponent from './SpokenPlacesComponent'

const SpokenPlacesContainer = ({spokenPlaceList}) => {
  if (spokenPlaceList && spokenPlaceList.length) {
    const list = spokenPlaceList
      .map(
        ({ id, comment = '' }) => {
          const commentText = comment
            ? ` ${comment}`
            : ''

          return `${placeCollection[id].name}${commentText}`
        }
      )
      .sort()

    return (
      <SpokenPlacesComponent list={list} />
    )
  }

  return null
}

export default SpokenPlacesContainer
