import React from 'react'

import Language from '@/components/Language'
import LanguageCatalog from '@/components/LanguageCatalog'

import {languageCollection} from '@/constants/languageList'

import CommonCatalogPageTemplate from '@/templates/CommonCatalogPageTemplate'

const defaultSeoData = {
  title: 'Языки',
  description: 'Каталог языков в Dungeons & Dragons',
}

const LanguagePageTemplate = (props) => (
  <CommonCatalogPageTemplate
    defaultSeoData={defaultSeoData}
    itemCollection={languageCollection}
    ItemComponent={Language}
    ListComponent={LanguageCatalog}
    {...props}
  />
)

export default LanguagePageTemplate
