const {
  GENDER_MALE,
} = require('./../../../genderList')
const {
  PLACE_VILHON_REACH,
} = require('./../../../placeIdList')

module.exports = {
  id: PLACE_VILHON_REACH,
  name: {
    nominative: 'Вилхонов Предел',
    genitive: 'Вилхонова Предела',
    dative: 'Вилхонову Пределу',
    accusative: 'Вилхонов Предел',
    instrumental: 'Вилхоновым Пределом',
    prepositional: 'Вилхоновом Пределе',
  },
  nameEn: 'the Vilhon Reach',
  genderId: GENDER_MALE,
}
