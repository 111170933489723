const {
  GENDER_MALE,
} = require('./../../../genderList')
const {
  PLACE_RUATHYM,
} = require('./../../../placeIdList')

module.exports = {
  id: PLACE_RUATHYM,
  name: {
    nominative: 'Руатим',
    genitive: 'Руатима',
    dative: 'Руатиму',
    accusative: 'Руатим',
    instrumental: 'Руатимом',
    prepositional: 'Руатиме',
  },
  nameEn: 'Ruathym',
  genderId: GENDER_MALE,
}
