const {
  GENDER_MALE,
} = require('./../../../genderList')
const {
  PLACE_KARA_TUR,
} = require('./../../../placeIdList')

module.exports = {
  id: PLACE_KARA_TUR,
  name: {
    nominative: 'Кара-Тур',
    genitive: 'Кара-Тура',
    dative: 'Кара-Туру',
    accusative: 'Кара-Тур',
    instrumental: 'Кара-Туром',
    prepositional: 'Кара-Туре',
  },
  nameEn: 'Kara-Tur',
  genderId: GENDER_MALE,
}
