const {
  GENDER_MALE,
} = require('./../../../genderList')
const {
  PLACE_THESK,
} = require('./../../../placeIdList')

module.exports = {
  id: PLACE_THESK,
  name: {
    nominative: 'Теск',
    genitive: 'Теска',
    dative: 'Теску',
    accusative: 'Теск',
    instrumental: 'Теском',
    prepositional: 'Теске',
  },
  nameEn: 'Thesk',
  genderId: GENDER_MALE,
}
