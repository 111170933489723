const {
  GENDER_MALE,
} = require('./../../../genderList')
const {
  PLACE_SESPECH,
} = require('./../../../placeIdList')

module.exports = {
  id: PLACE_SESPECH,
  name: {
    nominative: 'Сеспеч',
    genitive: 'Сеспеча',
    dative: 'Сеспечу',
    accusative: 'Сеспеч',
    instrumental: 'Сеспечем',
    prepositional: 'Сеспече',
  },
  nameEn: 'Sespech',
  genderId: GENDER_MALE,
}
