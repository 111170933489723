const {
  GENDER_FEMALE,
} = require('./../../../genderList')
const {
  PLACE_LAPALIIYA,
} = require('./../../../placeIdList')

module.exports = {
  id: PLACE_LAPALIIYA,
  name: {
    nominative: 'Лапалия',
    genitive: 'Лапалии',
    dative: 'Лапалии',
    accusative: 'Лапалию',
    instrumental: 'Лапалией',
    prepositional: 'Лапалии',
  },
  nameEn: 'Lapaliiya',
  genderId: GENDER_FEMALE,
}
