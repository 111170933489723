const {
  GENDER_MALE,
} = require('./../../../genderList')
const {
  PLACE_GREAT_GLACIER,
} = require('./../../../placeIdList')

module.exports = {
  id: PLACE_GREAT_GLACIER,
  name: {
    nominative: 'Великий Ледник',
    genitive: 'Великого Ледника',
    dative: 'Великому Леднику',
    accusative: 'Великий Ледник',
    instrumental: 'Великим Ледником',
    prepositional: 'Великом Леднике',
  },
  nameEn: 'Great Glacier',
  genderId: GENDER_MALE,
}
