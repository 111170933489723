const {
  GENDER_MALE,
} = require('./../../../genderList')
const {
  PLACE_THINDOL,
} = require('./../../../placeIdList')

module.exports = {
  id: PLACE_THINDOL,
  name: {
    nominative: 'Тиндол',
    genitive: 'Тиндола',
    dative: 'Тиндолу',
    accusative: 'Тиндол',
    instrumental: 'Тиндолом',
    prepositional: 'Тиндоле',
  },
  nameEn: 'Thindol',
  genderId: GENDER_MALE,
}
