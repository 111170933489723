const listToCollectionById = require('./../../utils/listToCollectionById')

const placeRawList = require('./list')

const placeList = placeRawList.map(
  (item, i) => ({
    ...item,
    name: item.name.nominative,
    nameByCase: item.name,
  })
)

module.exports = placeList

module.exports.placeCollection = listToCollectionById(placeList)
