import arrify from 'arrify'
import React from 'react'

import { languageCollection } from '@/constants/languageList'
import generateNameStr from '@/utils/generateNameStr'

import LanguageComponent from './LanguageComponent'

export default ({ id }) => {
  if (id) {
    const language = languageCollection[id]

    const { alphabetId } = language
    const { header, subHeader } = generateNameStr(language)

    return (
      <LanguageComponent
        alphabetIdList={arrify(alphabetId)}
        header={header}
        subHeader={subHeader}
        language={language}
        {...language}
      />
    )
  }

  return null
}
