import React from 'react'

import SourceInfo from '@/components/SourceInfo'
import DescriptionList from "@/components/DescriptionList"
import Dictionary from "@/components/Dictionary"
import PageHeader from '@/components/PageHeader'

import { alphabetCollection } from '@/constants/alphabetList'

import joinLastItem from '@/utils/joinLastItem'

import AlphabetImageList from './components/AlphabetImageList'
import DialectList from './components/DialectList'
import DialectOf from './components/DialectOf'
import LanguageFamily from './components/LanguageFamily'
import LanguageGroup from './components/LanguageGroup'
import LanguageSubGroup from './components/LanguageSubGroup'
import LanguageType from './components/LanguageType'
import SpokenPlaces from './components/SpokenPlaces'

import './LanguageStyles.less'

const LanguageComponent = (
  {
    alphabetIdList,
    description,
    dialectOf,
    language,
    header,
    source,
    subHeader,
    typicalSpeakers,
    ...rest
  },
) => (
  <section className='Language'>
    <section className='Language_infoBlock'>
      <PageHeader
        className='Language_header'
        {...rest}
      />
      <DialectOf langId={dialectOf} />
      <LanguageType {...language} />
      <p>
        <b>Письменность: </b>
        {
          alphabetIdList.length
            ? joinLastItem(alphabetIdList.map(id => alphabetCollection[id].name))
            : 'Нет'
        }
      </p>
      {typicalSpeakers && (
        <p><b>Типичные носители:</b> {typicalSpeakers}</p>
      )}
      <SpokenPlaces {...language} />
      <DialectList {...language} />
      <LanguageFamily {...language} />
      <LanguageGroup {...language} />
      <LanguageSubGroup {...language} />
      <SourceInfo
        className='Language_source'
        source={source}
        useFullName
      />
    </section>
    {
      description && (
        <section className='Language_descriptionBlock'>
          <header className='Language_descriptionBlockHeader'>Описание {language.nameByCase.genitive}</header>
          <section className='Language_descriptionList'>
            <DescriptionList
              {...language}
              description={description}
            />
          </section>
        </section>
      )
    }
    <Dictionary {...language} />
    {
      alphabetIdList
        ? alphabetIdList.map(
          alphabetId => (
            <section
              className='Language_descriptionBlock'
              key={alphabetId}
            >
              <header className='Language_descriptionBlockHeader'>Описание алфавита {alphabetCollection[alphabetId].name}</header>
              <section className='Language_descriptionList'>
                <DescriptionList description={alphabetCollection[alphabetId].description}/>
                <AlphabetImageList id={alphabetId}/>
              </section>
            </section>
          )
        )
        : null
    }
  </section>
)

export default LanguageComponent
